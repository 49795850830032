export const services = [
    {
        number: '01',
        image: "assets/img/service/icon-1.png",
        title: "Mobile Development",
        description: "We can design cross-platform mobile application by the help of flutter,view Best Candidate Mobile App."
    },
    {
        number: '02',
        image: "assets/img/service/icon-2.png",
        title: "Web Devlopment",
        description: "I can design web application by the help of React, reach out for samples"
    },
    {
        number: '03',
        image: "assets/img/service/icon-3.png",
        title: "SEO Friendly",
        description: "We can  optimize your website for search engines visibility and performance."
    },
]
export const experience = [
    {
        date: "June 13, 2024",
        category: "Auto CV Builder",
        title: "Best Candidate",
        description: "While logging in you have to provide your details which will help you generate your CV automatically."
    },
    {
        date: "June 13, 2024",
        category: "Request for an Online Portfolio",
        title: "Best Candidate",
        description: "Request for your online portfolio and get the a message from us"
    },
    {
        date: "June 13, 2024",
        category: "Motivation",
        title: "Best Candidate",
        description: "Motivational quotes will be appearing on the motivation screen, this helps you to be enlightened at all time."
    },
] 
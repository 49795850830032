import { Fade } from 'react-awesome-reveal'

function About() {
    return (
        <div>
            <section id="about" className={`bx-about-section bx-section padding-b-80 ${"#about" ? "padding-top" : ""}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <Fade triggerOnce duration={2000} direction='up' delay={300} className="sec-img">
                                <img src="assets/img/about/about.png" alt="" />
                            </Fade>
                        </div>
                        <Fade triggerOnce duration={2000} direction='up' delay={300} className="col-md-12 col-lg-6">
                            <div className="detailed-content">
                                <div className="title">
                                    <p className="light-txt">About Best Candidate </p>
                                    <h2>Create a great 
                                        first <span className="primary-clr"> impression</span></h2>
                                    <p>
                                        Are you looking for a job? Then Best Candidate is the app for you, It is an official application dedicated to help individuals searching for jobs, the application creates a Cv's for the user and design their portfolios, reach out for more informations.
                                    </p>
                                </div>
                                <div className="personal-detail">
                                    <div className="content">
                                        <div className="left">
                                            <div className="name pb">
                                                <span className="info">Regards</span>
                                                <span className="detail">Best Candidate </span>
                                            </div>
                                            <div className="age pb">
                                                <span className="info">Year of production</span>
                                                <span className="detail">2024</span>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="ph pb">
                                                <span className="info">Help</span>
                                                <span className="detail">+254750349889</span>
                                            </div>

                                            <div className="email pb">
                                                <span className="info">Email :</span>
                                                <span className="detail">collinsdeveloper0@gmail.com</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mail">
                                        <div className="mail">
                                            <div className="address">
                                                <span className="info">Address : </span>
                                                <span className="detail">Kitale, Nairobi, Kenya</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About

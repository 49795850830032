export const news = [
    {
        image: "assets/img/news/img-1.jpg",
    },
    {
        image: "assets/img/news/img-1.jpg",
    },
    {
        image: "assets/img/news/img-1.jpg",
    },

]
export const headermanu = [

    {
        href: "#home",
        title: "Home"
    },

    {
        href: "#about",
        title: "About"
    },


    {
        href: "#experience",
        title: "Achievements"
    },


    {
        href: "#portfolio",
        title: "Gallery"
    },

    {
        href: "#news",
        title: "News"
    },

    {
        href: "#contact",
        title: "Contact Us"
    },

]
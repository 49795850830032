export const portfolio = [

    {
        description : "Best Candidate Logo",
        title : "App Logo",
        subtitle : "",
        image : "assets/img/portfolio/img-1.jpg",
        categories: ['graphics','templates'],
        link: "assets/img/portfolio/img-1.jpg"
    }, 
    {
        description : "Best Candidate Website",
        title : "Best Candidate Website",
        image : "assets/img/portfolio/img-2.jpg",
        categories: ['design'],
        link: "assets/img/portfolio/img-2.jpg"
    }, 
    {
        description : "Astret Consultant Moble App Will be Launched soon...",
        title : "Mobile Design",
        image : "assets/img/portfolio/img-3.jpg",
        categories: ['design',],
        link: "assets/img/portfolio/img-3.jpg"
    }, 
    {
        description : "Together Mobile App",
        title : "Development",
        image : "assets/img/portfolio/img-4.jpg",
        categories: ['development',],
        link: "assets/img/portfolio/img-4.jpg"
    }, 
    {
        description : "Together Website",
        title : "Web Design",
        title1 : "Templates",
        image : "assets/img/portfolio/img-2.jpg",
        categories: ['design','templates'],
        link: "assets/img/portfolio/img-2.jpg"
    }, 
    {
        description : "",
        title : "3D Graphics",
        title1 : "Development",
        image : "assets/img/portfolio/img-2.jpg",
        categories: ['graphics','development'],
        link: "assets/img/portfolio/img-1.jpg"
    }, 

]